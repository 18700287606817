import React from 'react';
import Swal from 'sweetalert2';

function Contact() {
    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
    
        formData.append("access_key", "217935bc-cbf0-455e-8973-a2ddd8f5020c");
    
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formData
        });
    
        const data = await response.json();
    
        if (data.success) {
            event.target.reset();
            Swal.fire({
              title: "Sucesso!",
              text: "Mensagem enviada com sucesso!",
              icon: "success"
            });
        } else {
            console.log("Erro", data);
            Swal.fire({
              title: "Erro",
              text: data.message || "Algo deu errado. Tente novamente.",
              icon: "error"
            });
        }
    };

    return (
        <section className="container">
            <div className="contact" id="contact">
                <h2>Contato</h2>
                <h3>Gostou do meu trabalho? Não perca tempo! Entre em contato comigo :)</h3>
            </div>
            <div className="container-form">
                <form onSubmit={onSubmit} className="form">
                    <input className="input" type="text" id="name" name="name" placeholder="Seu nome" required/>
                    <input className="input" type="email" id="email" name="email" placeholder="Email" required/>
                    <textarea className="textarea" id="message" name="message" placeholder="Digite sua mensagem..."/>
                    <input className="form-btn" type="submit" value="Enviar mensagem"/>
                </form>
            </div>
            <div className="contactBtns">
                <a href="https://www.linkedin.com/in/olstayna" target="_blank" rel="noreferrer" className="btnContact" aria-label="LinkedIn">
                    <span>LinkedIn</span>
                </a>
                <a href="https://github.com/olstayna" target="_blank" rel="noreferrer" className="btnContact" aria-label="GitHub">
                    <span>GitHub</span>
                </a>
                <a href="mailto:contato@taynasantana.com.br" className="btnContact" aria-label="Enviar e-mail para contato@taynasantana.com.br">
                    <span>contato@taynasantana.com.br</span>
                </a>
            </div>
        </section>
    );
}

export default Contact;