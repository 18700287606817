import { FaHtml5, FaCss3Alt, FaBootstrap, FaJs, FaPhp, FaLaravel, FaSass } from 'react-icons/fa';
import { GrMysql } from "react-icons/gr";
import { SiNextdotjs } from "react-icons/si";

const Icons = {
    "FaHtml5": FaHtml5,
    "FaCss3Alt": FaCss3Alt,
    "FaBootstrap": FaBootstrap,
    "FaJs": FaJs,
    "FaPhp": FaPhp,
    "GrMysql": GrMysql,
    "FaLaravel": FaLaravel,
    "SiNextdotjs": SiNextdotjs,
    "FaSass": FaSass
};

export default Icons;